<script>
import { mapActions } from 'vuex'
import managementMixin from '@/modules/Management/managementMixin'
import { required, requiredIf, between, minLength } from 'vuelidate/lib/validators'
import { VMoney } from 'v-money'
import { isNull } from 'lodash'
import { mask } from 'vue-the-mask'

export default {
  name: 'ManagementGeneral',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Action: () => import('@/components/general/Action'),
    FormSection: () => import('@/components/general/FormSection'),
    Radio: () => import('@/components/general/Radio'),
    FilterList: () => import('@/components/general/FilterList'),
    InputField: () => import('@/components/general/InputField'),
    Tabs: () => import('@/components/general/Tabs'),
    SelectField: () => import('@/components/general/SelectField'),
    Tagify: () => import('@/components/general/Tagify'),
    Pagination: () => import('@/components/general/Pagination'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  directives: {
    money: VMoney,
    mask
  },

  mixins: [ managementMixin ],

  data () {
    return {
      generalTabs: [
        {
          text: this.$t('management.tabs:link.SAS')
        },
        {
          text: this.$t('management.tabs:link.search_engine')
        },
        {
          text: this.$t('management.tabs:link.payments')
        },
        {
          text: this.$t('management.tabs:link.RAE')
        },
        {
          text: this.$t('management.tabs:link.moodle')
        },
        {
          text: this.$t('management.tabs:link.sparkpost')
        }
      ],

      yesNoOptions: [
        {
          label: this.$t('global:yes'),
          value: true
        },
        {
          label: this.$t('global:no'),
          value: false
        }
      ],

      activeTab: 0,
      providerList: [],
      rmList: [],
      sasUnityList: [],
      sasActionList: [],
      sasProjectList: [],
      paymentTypeList: [
        {
          text: 'Cartão de Crédito',
          value: 1,
          alias: 'financial_allows_credit_card'
        },
        {
          text: 'Dois Cartões de Crédito',
          value: 2,
          disabled: true,
          alias: 'financial_allows_two_credit_cards'
        },
        {
          text: 'Boleto',
          value: 3,
          disabled: true,
          alias: 'financial_allows_boleto'
        },
        {
          text: 'Cartão de Débito',
          value: 4,
          disabled: true,
          alias: 'financial_allows_debit_card'
        },
        {
          text: 'PIX',
          value: 5,
          disabled: true,
          alias: 'financial_allows_pix'
        }
      ],

      formData: {
        financial_credit_card_allows_installment: false,
        financial_credit_card_maximum_amount_installments: 2,
        financial_credit_card_minimum_installment_value: '0',
        financial_provider: null,
        financial_allows_credit_card: false,
        paymentType: null,
        rm_enable_integration: false,
        sas_id_project: null,
        sas_id_action: null,
        sas_notify_email: null,
        sas_id_service_type: null,
        sas_id_modality: null,
        sas_id_instrument: null,
        sas_id_responsable: null,
        sas_id_attendance: null,
        sas_id_credentiated_employee: null,
        sas_name_instructure: null,
        sas_id_unity: null,
        sas_address: null,
        sas_complement: null,
        sas_cep: null,
        sas_city_code: null,
        sas_neighborhood_code: null,
        sas_state_code: null,
        search_engine_stop_words: null,
        search_engine_searchable_attributes: null,
        search_engine_synonyms: null,
        rm_provider: null,
        rm_url: null,
        rm_user: null,
        rm_password: null,
        rm_password_base64: null,
        rm_centro_de_custo: null,
        distribute_cost_center: false,
        rm_distribute_cost_center_list: [],
        financial_receipt: null,
        rae_product_code_initial: null,
        rae_product_code_expired: null,
        rae_product_code_dropout: null,
        rae_product_code_canceled: null,
        moodle_token: null,
        moodle_url: null,
        sparkpost_api_url: null,
        sparkpost_key: null
      },

      editable: [],
      synonyms: [],
      searchable_attributes: [],
      searchableAttributesList: [
        {
          text: this.$t('management.general:searchengine.attribute.name'),
          value: 'name'
        },
        {
          text: this.$t('management.general:searchengine.attribute.description'),
          value: 'description'
        },
        {
          text: this.$t('management.general:searchengine.attribute.call_text'),
          value: 'call_text'
        },
        {
          text: this.$t('management.general:searchengine.attribute.audience'),
          value: 'audience'
        },
        {
          text: this.$t('management.general:searchengine.attribute.differentials'),
          value: 'differentials'
        },
        {
          text: this.$t('management.general:searchengine.attribute.tags'),
          value: 'tags'
        }
      ],

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false, // doesn't work with directive
        allowBlank: true
      },

      modalRm: false,
      paginationCostCenter: {
        page: 1,
        lastPage: 4,
        limit: 40
      },

      receiptList: [
        {
          text: 'Padrão',
          value: 'padrao'
        },
        {
          text: 'Alternativo',
          value: 'alternativo'
        }
      ]
    }
  },

  validations: {
    formData: {
      financial_credit_card_allows_installment: {
        required: (event) => {
          return event !== null && event !== undefined
        }
      },

      financial_credit_card_minimum_installment_value: {
        required: requiredIf(function () {
          return this.formData.financial_credit_card_allows_installment
        })
      },

      financial_credit_card_maximum_amount_installments: {
        required: requiredIf(function () {
          return this.formData.financial_credit_card_allows_installment
        }),

        between: between(2, 12)
      },

      paymentType: {
        required
      },

      financial_provider: {
        required
      },

      sas_id_project: {
        type: String,
        required: true
      },

      sas_id_action: {
        type: String,
        required: true
      },

      sas_notify_email: {
        type: String,
        required: true
      },

      sas_id_service_type: {
        type: String,
        required: true
      },

      sas_id_modality: {
        type: String,
        required: true
      },

      sas_id_instrument: {
        type: String,
        required: true
      },

      sas_id_responsable: {
        type: String,
        required: true
      },

      sas_id_attendance: {
        type: String,
        required: true
      },

      sas_id_credentiated_employee: {
        type: String,
        required: true
      },

      sas_name_instructure: {
        type: String,
        required: true
      },

      sas_id_unity: {
        type: String,
        required: true
      },

      sas_address: {
        type: String,
        required: true
      },

      sas_complement: {
        type: String,
        required: true
      },

      sas_cep: {
        type: String,
        required: true
      },

      sas_city_code: {
        type: String,
        required: true
      },

      sas_neighborhood_code: {
        type: String,
        required: true
      },

      sas_state_code: {
        type: String,
        required: true
      },

      search_engine_stop_words: {
        type: String,
        required: false
      },

      search_engine_searchable_attributes: {
        type: String,
        required: false
      },

      search_engine_synonyms: {
        type: String,
        required: false
      },

      rm_enable_integration: {
        required: (event) => {
          return event !== null && event !== undefined
        }
      },

      rm_provider: {
        required: requiredIf(function () {
          return this.formData.rm_enable_integration
        })
      },

      rm_url: {
        required: requiredIf(function () {
          return this.formData.rm_enable_integration
        })
      },

      rm_user: {
        required: requiredIf(function () {
          return this.formData.rm_enable_integration
        })
      },

      rm_password: {
        required: requiredIf(function () {
          return this.formData.rm_enable_integration
        })
      },

      rm_password_base64: {
        required: requiredIf(function () {
          return this.formData.rm_enable_integration
        })
      },

      rm_centro_de_custo: {
        required,
        alphaNum: (value) => {
          if (!value) return true

          return /^[0-9.-]+$/.test(value)
        },

        minLength: minLength(16)
      },

      distribute_cost_center: {
        required
      },

      rm_distribute_cost_center_list: {

        $each: {
          costCenter: {
            required: requiredIf(function () {
              return this.formData.distribute_cost_center
            }),

            alphaNum: (value) => {
              if (!value) return true

              return /^[0-9.-]+$/.test(value)
            },

            minLength: minLength(16)
          }
        }
      },

      financial_receipt: {
        required
      },

      rae_product_code_initial: {
        required
      },

      rae_product_code_expired: {
        required
      },

      rae_product_code_dropout: {
        required
      },

      rae_product_code_canceled: {
        required
      },

      moodle_token: {
        required
      },

      moodle_url: {
        required
      }
    }
  },

  computed: {
    canWriteSearchEngine () {
      return this.getContextPermission('search_engine') === 'write'
    },

    isCreditCardSelected () {
      return this.formData.paymentType?.includes(1)
    },

    cantSaveFinancialSettings () {
      return (this.formData.financial_provider === undefined || this.formData.financial_provider === null) ||
        (this.formData.paymentType === null || this.formData.paymentType.length === 0) ||
        this.minimumInstallmentValueHasError || this.maximumAmountInstallmentsIsInvalid ||
        this.rmConfigMisfilled
    },

    rmConfigMisfilled () {
      return !!this.formData.rm_enable_integration &&
              (!this.formData.rm_provider ||
              !this.formData.rm_url ||
              !this.formData.rm_user ||
              !this.formData.rm_password ||
              !this.formData.rm_password_base64)
    },

    minimumInstallmentValueHasError () {
      return this.formData.financial_provider &&
        this.formData.financial_credit_card_allows_installment &&
        (this.formData.financial_credit_card_minimum_installment_value === 0 ||
          this.formData.financial_credit_card_minimum_installment_value === 'R$ 0,00')
    },

    maximumAmountInstallmentsIsInvalid () {
      return this.formData.financial_provider &&
        this.formData.financial_credit_card_allows_installment &&
        (this.formData.financial_credit_card_maximum_amount_installments === null ||
          this.formData.financial_credit_card_maximum_amount_installments === undefined ||
          this.formData.financial_credit_card_maximum_amount_installments === '')
    },

    paginationListER () {
      const init = (this.paginationCostCenter.page - 1) * 10
      const final = this.paginationCostCenter.page * 10
      const list = this.formData.rm_distribute_cost_center_list.slice(init, final)

      return list
    }
  },

  watch: {
    'formData.sas_id_project' (event) {
      this.setFetching(true)
      this.sasActionList = []

      this.getSasActionList(event).finally(() => {
        this.setFetching(false)
      })
    },

    'searchable_attributes' () {
      this.formData.search_engine_searchable_attributes = ''

      this.searchable_attributes.forEach(element => {
        this.formData.search_engine_searchable_attributes += `${element} `
      })
    },

    'formData.financial_provider' (event) {
      if (event === null) {
        this.resetFinancialFields()
      }
    },

    'formData.financial_credit_card_allows_installment' (event) {
      if (event === false) {
        this.resetCreditCardInstallmentFields()
      }
    },

    'formData.rm_enable_integration' (event) {
      if (event === false) {
        this.resetRmIntegrationFields()
      }
    }
  },

  created () {
    if (this.$route.params.tab === 'finance') {
      this.changeTab(2)
    }

    this.setup()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetGeneralSettings',
      'attemptSaveGeneralSettings',
      'attemptGetSasUnityList',
      'attemptGetSasActionList',
      'attemptGetSasProjectList',
      'attemptGetProviderList',
      'attemptGetRmProviderList',
      'attemptGetFinancialSettings',
      'attemptSaveFinancialSettings',
      'attemptGetERList',
      'attemptSaveERList'
    ]),

    ...mapActions('FinanceModule', [ 'attemptSyncAllOrders' ]),

    setup () {
      this.setFetching(true)

      Promise.all([
        this.getSasUnityList(),
        this.getSasProjectList(),
        this.getProviderList(),
        this.getRmProviderList(),
        this.getRegionalOfficeList()
      ]).finally(() => {
        this.attemptGetGeneralSettings().then(({ data }) => {
          data.data.forEach(config => {
            this.formData[config.field] = config.active ? config.value : ''
            this.editable[config.field] = config.editable

            if (config.active && config.field === 'sas_id_unity') {
              this.formData[config.field] = parseInt(config.value, 10)
            }

            if (config.active && config.field === 'sas_id_project') {
              this.formData[config.field] = parseInt(config.value, 10)
            }

            if (config.active && config.field === 'sas_id_action') {
              this.formData[config.field] = parseInt(config.value, 10)
            }

            if (config.active && config.field === 'search_engine_stop_words') {
              let parsedStopWords = this.parseStopWords(config.value)

              parsedStopWords = JSON.parse(JSON.stringify(parsedStopWords))
              this.formData[config.field] = parsedStopWords
            }

            if (config.active && config.field === 'financial_provider') {
              this.formData[config.field] = config.value
            }

            if (config.active && config.field === 'financial_credit_card_allows_installment') {
              this.formData[config.field] = parseInt(config.value) === 1 || config.value === true ||
                config.value === 'true'
            }

            if (config.active && config.field === 'rm_enable_integration') {
              this.formData[config.field] = parseInt(config.value) === 1 || config.value === true ||
                  config.value === 'true'
            }

            if (config.field.includes('allows') && config.field !== 'financial_credit_card_allows_installment') {
              const field = this.paymentTypeList.filter(item => item.alias === config.field && config.value === 'true')

              if (this.formData.paymentType === null || this.formData.paymentType === undefined) {
                this.formData.paymentType = []
              }

              if (field.length) {
                this.formData.paymentType.push(field.shift().value)
              }
            }

            if (config.active && config.field === 'financial_receipt') {
              this.formData[config.field] = config.value
            }

            if (config.active && config.field === 'distribute_cost_center') {
              this.formData[config.field] = parseInt(config.value) === 1 || config.value === true ||
                  config.value === 'true'
            }
          })

          this.parseSynonyms()
          this.parseAttribute()
        }).finally(() => {
          this.setFetching(false)
        })
      })
    },

    changeTab (index) {
      this.activeTab = index
    },

    submitSAS () {
      this.stringifySynonyms()
      const configurations = []
      const sendEmptyConfig = [
        'search_engine_stop_words',
        'search_engine_searchable_attributes',
        'search_engine_synonyms'
      ]

      Object.keys(this.formData).forEach(key => {
        let value = this.formData[key]
        const active = !!value

        if (active === false && sendEmptyConfig.includes(key)) {
          value = 'null'
        }

        !!value && (value !== 'null' || key === 'search_engine_stop_words') && key !== 'sas_client_api' && key !== 'sas_client_token' &&
          !key.includes('financial') && !key.includes('system') && !key.includes('highlight') && key !== 'paymentType' && configurations.push({
          field: key,
          value: key === 'search_engine_stop_words' && value !== 'null'
            ? Object.keys(value).length === 1
              ? value[0]
              : JSON.parse(value).map(item => item.value).join()
            : value,
          active: active
        })
      })

      this.setFetching(true)

      this.attemptSaveGeneralSettings({ configurations: JSON.stringify(configurations) })
        .then(() => {
          this.setFeedback({ message: this.$t('management.general:submit.feedback.success') })
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('management.general:submit.feedback.error') })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitFinancialConfiguration () {
      const configurations = []

      Object.keys(this.formData).forEach(key => {
        let value = this.formData[key]

        let active = !!value

        if (active === false && key !== 'financial_credit_card_allows_installment' && key !== 'rm_enable_integration' && key !== 'distribute_cost_center') {
          value = 'null'
        }

        if (key === 'financial_credit_card_allows_installment') {
          value = `${value}`
          active = true
        }

        if (key === 'financial_credit_card_minimum_installment_value') {
          value = this.unformat(value)
          value = (value === '0' || value === 0 || value === 0.0) ? '0.00' : value
        }

        if ([
          'rm_provider',
          'rm_url',
          'rm_user',
          'rm_password',
          'rm_password_base64'
        ].includes(key)) {
          value = (isNull(value) || value === 'null' || value === '') ? ' ' : value
        }

        if (key === 'rm_enable_integration') {
          value = `${value}`
          active = true
        }

        if (key === 'distribute_cost_center') {
          value = `${value}`
          active = true
        }

        !!value && value !== 'null' && !key.includes('sas') && !key.includes('search') && !key.includes('rm_distribute_cost_center_list') && !key.includes('system') && !key.includes('rae') && !key.includes('highlight') && configurations.push({
          field: key,
          value: value,
          active: active
        })
      })

      const formatedListCostCenterList = this.formData.rm_distribute_cost_center_list.map(({ id, costCenter }) => {
        return {
          idRmIntegration: id,
          costCenter
        }
      })

      this.$v.formData.rm_distribute_cost_center_list.$touch()
      this.$v.formData.rm_centro_de_custo.$touch()

      if (!this.$v.formData.rm_distribute_cost_center_list.$invalid && !this.$v.formData.rm_centro_de_custo.$invalid) {
        this.setFetching(true)

        const listPromise = [ this.attemptSaveGeneralSettings({ configurations: JSON.stringify(configurations) }) ]

        if (this.formData.distribute_cost_center) {
          listPromise.push(this.attemptSaveERList({ rmIntegrations: formatedListCostCenterList }))
        }

        Promise.all(listPromise).then(() => {
          this.setFeedback({ message: this.$t('management.general:submit.feedback.success') })

          if (this.formData.rm_enable_integration === true) {
            this.modalRm = true
          }
        }).catch(() => {
          this.setFeedback({ message: this.$t('management.general:submit.feedback.error') })
        })
          .finally(() => {
            this.setFetching(false)
          })
      } else {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.validation.error') })

        if (this.$v.formData.rm_distribute_cost_center_list.$invalid) {
          this.goToPageWithError()
        }

        this.$nextTick(() => {
          const hasErrorElements = document.querySelector('.has-error')
          const top = hasErrorElements.offsetTop - 50

          document.querySelector('html')
            .scroll({
              top: top,
              behavior: 'smooth'
            })
        })
      }
    },

    hasAttribute (attribute) {
      return this.searchable_attributes.includes(attribute)
    },

    changeAttribute (attribute) {
      if (this.hasAttribute(attribute) === false) {
        this.addAttribute(attribute)

        return
      }

      this.removeAttribute(attribute)
    },

    addAttribute (attribute) {
      this.searchable_attributes.push(attribute)
    },

    removeAttribute (attribute) {
      this.searchable_attributes = this.searchable_attributes.filter(element => {
        return (element !== attribute)
      })
    },

    parseAttribute () {
      if (this.formData.search_engine_searchable_attributes === null) {
        return
      }

      this.formData.search_engine_searchable_attributes.split(' ').forEach(element => {
        this.searchable_attributes.push(element)
      })
    },

    addSynonyms () {
      this.synonyms.push({ item: null })
    },

    removeSynonym (index) {
      this.synonyms.splice(index, 1)
    },

    stringifySynonyms () {
      this.formData.search_engine_synonyms = ''

      this.synonyms.forEach(element => {
        this.formData.search_engine_synonyms += element.item + '\n'
      })
    },

    parseStopWords (configValue) {
      return configValue.split(',')
    },

    parseSynonyms () {
      if (this.formData.search_engine_synonyms === null) {
        return
      }

      this.formData.search_engine_synonyms.split('\n').forEach(element => {
        this.synonyms.push({ item: element })
      })
    },

    getSasUnityList () {
      return this.attemptGetSasUnityList({
        filter: {
          active: 'true',
          id_type: 5
        },
        order: { description: 'ASC' }
      }).then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            this.sasUnityList.push({
              text: element.vendorId + ' - ' + element.description,
              value: element.id
            })
          })

          return true
        }
      })
    },

    getSasActionList (sasProjectId) {
      return this.attemptGetSasActionList({
        filter: {
          active: 'true',
          project: sasProjectId
        },
        order: { description: 'ASC' }
      }).then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            this.sasActionList.push({
              text: element.vendorId + ' - ' + element.description,
              value: element.id
            })
          })

          return true
        }
      })
    },

    getSasProjectList () {
      return this.attemptGetSasProjectList({
        filter: {
          active: 'true',
          action: 'true'
        },
        order: { description: 'ASC' }
      }).then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            this.sasProjectList.push({
              text: element.vendorId + ' - ' + element.description,
              value: element.id
            })
          })

          return true
        }
      })
    },

    getProviderList () {
      return this.attemptGetProviderList({
        filter: {
          active: 'true',
          action: 'true'
        },
        order: { name: 'ASC' }
      }).then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            this.providerList.push({
              text: element.name,
              value: element.alias
            })
          })

          return true
        }
      })
    },

    getRmProviderList () {
      return this.attemptGetRmProviderList({
        filter: {
          active: 'true',
          action: 'true'
        },
        order: { name: 'ASC' }
      }).then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            this.rmList.push({
              text: element.name,
              value: element.alias
            })
          })

          return true
        }
      })
    },

    getRegionalOfficeList () {
      return this.attemptGetERList(this.paginationCostCenter).then(({ data }) => {
        this.formData.rm_distribute_cost_center_list = data.data

        return true
      })
    },

    isEditable (attribute) {
      return this.editable[attribute] === true || this.editable[attribute] === undefined
    },

    resetFinancialFields () {
      this.formData.financial_credit_card_allows_installment = false
      this.formData.financial_allows_credit_card = false
      this.formData.paymentType = null

      this.resetCreditCardInstallmentFields()
    },

    resetCreditCardInstallmentFields () {
      this.formData.financial_credit_card_maximum_amount_installments = 2
      this.formData.financial_credit_card_minimum_installment_value = '0'
    },

    resetRmIntegrationFields () {
      this.formData.rm_enable_integration = false
      this.formData.rm_provider = ''
      this.formData.rm_url = ''
      this.formData.rm_user = ''
      this.formData.rm_password = ''
      this.formData.rm_password_base64 = ''
    },

    unformat (value) {
      const negative = value.indexOf('-') >= 0 ? -1 : 1
      const numbers = value.toString().replace(/\D+/g, '') || '0'
      const currency = this.numbersToCurrency(numbers)

      return parseFloat(currency) * negative
    },

    numbersToCurrency (numbers) {
      const exp = Math.pow(10, this.money.precision)
      const float = parseFloat(numbers) / exp

      return float.toFixed(this.fixed(this.money.precision))
    },

    fixed () {
      return this.between(0, this.money.precision, 20)
    },

    between (min, n, max) {
      return Math.max(min, Math.min(n, max))
    },

    syncRm () {
      this.modalRm = false
      this.setFetching(true)

      this.attemptSyncAllOrders().then(() => {
        this.setFeedback({ message: this.$t('management.general:feedback.syncall.success') })
      }).catch(() => {
        this.setFeedback({ message: this.$t('management.general:feedback.syncall.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    cancelRemove () {
      this.modalRm = false
    },

    formatRegionalOffice (str) {
      return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    },

    async updateCostCenters () {
      this.setFetching(true)
      const formatedListCostCenterList = this.formData.rm_distribute_cost_center_list.map(({ id, costCenter }) => {
        return {
          idRmIntegration: id,
          costCenter
        }
      })

      this.attemptSaveERList({ rmIntegrations: formatedListCostCenterList }).then(() => {
        return true
      }).catch(() => {
        this.setFeedback({ message: this.$t('management.general:submit.feedback.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    goToPageWithError () {
      const indexCostError = this.formData.rm_distribute_cost_center_list.findIndex((_, index) => this.$v.formData.rm_distribute_cost_center_list.$each[index].costCenter.$invalid)

      if (indexCostError < 10) {
        this.paginationCostCenter.page = 1
      } else if (indexCostError < 20 && indexCostError >= 10) {
        this.paginationCostCenter.page = 2
      } else if (indexCostError >= 20 && indexCostError < 30) {
        this.paginationCostCenter.page = 3
      } else {
        this.paginationCostCenter.page = 4
      }
    },

    prevPage () {
      this.$v.formData.rm_distribute_cost_center_list.$touch()

      if (!this.$v.formData.rm_distribute_cost_center_list.$invalid) {
        this.updateCostCenters().then(() => {
          if (this.paginationCostCenter.page > 1) {
            this.paginationCostCenter.page -= 1
          }
        })
      } else {
        this.goToPageWithError()
      }
    },

    nextPage () {
      this.$v.formData.rm_distribute_cost_center_list.$touch()

      if (!this.$v.formData.rm_distribute_cost_center_list.$invalid) {
        this.updateCostCenters().then(() => {
          this.paginationCostCenter.page += 1
        })
      } else {
        this.goToPageWithError()
      }
    },

    firstPage () {
      this.$v.formData.rm_distribute_cost_center_list.$touch()

      if (!this.$v.formData.rm_distribute_cost_center_list.$invalid) {
        this.updateCostCenters().then(() => {
          this.paginationCostCenter.page = 1
        })
      } else {
        this.goToPageWithError()
      }
    },

    lastPage () {
      this.$v.formData.rm_distribute_cost_center_list.$touch()

      if (!this.$v.formData.rm_distribute_cost_center_list.$invalid) {
        this.updateCostCenters().then(() => {
          this.paginationCostCenter.page = this.paginationCostCenter.lastPage
        })
      } else {
        this.goToPageWithError()
      }
    },

    getIndexWithPage (index) {
      return this.paginationCostCenter.page - 1 ? `${this.paginationCostCenter.page - 1}${index}` : index
    }
  }
}
</script>

<template>
  <div class="main-content management-general">
    <ContentHeader
      :title="$t('management:header.title')"
      :description="$t('management:header.description')"
      background="/assets/images/themes/default/png/management_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>
    <div class="center-small general-settings">
      <FilterList>
        <Tabs
          slot="button"
          class="gamification-tabs"
          :links="generalTabs"
          :index-active="activeTab"
          @tabChange="changeTab"
        />
      </FilterList>
      <form
        v-if="activeTab === 0"
        class="management-settings_form"
        @submit.prevent="submitSAS"
      >
        <SelectField
          v-model="formData.sas_id_project"
          :label="$t('management.general:field.name.sas_id_project')"
          :items="sasProjectList"
          :validation="$v.formData.sas_id_project"
          :disabled="!isEditable('sas_id_project')"
        />
        <SelectField
          v-model="formData.sas_id_action"
          :label="$t('management.general:field.name.sas_id_action')"
          :items="sasActionList"
          :validation="$v.formData.sas_id_action"
          :disabled="!isEditable('sas_id_action')"
        />
        <SelectField
          v-model="formData.sas_id_unity"
          :label="$t('management.general:field.name.sas_id_unity')"
          :under-description="$t('solution.manage:field.description.sasUnity.code')"
          :items="sasUnityList"
          :validation="$v.formData.sas_id_unity"
          :disabled="!isEditable('sas_id_unity')"
        />
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_notify_email"
            :label="$t('management.general:field.name.sas_notify_email')"
            :validation="$v.formData.sas_notify_email"
            :disabled="!isEditable('sas_notify_email')"
          />
          <InputField
            v-model="formData.sas_id_service_type"
            :label="$t('management.general:field.name.sas_id_service_type')"
            :validation="$v.formData.sas_id_service_type"
            :disabled="!isEditable('sas_id_service_type')"
          />
        </div>
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_id_modality"
            :label="$t('management.general:field.name.sas_id_modality')"
            :validation="$v.formData.sas_id_modality"
            :disabled="!isEditable('sas_id_modality')"
          />
          <InputField
            v-model="formData.sas_id_instrument"
            :label="$t('management.general:field.name.sas_id_instrument')"
            :validation="$v.formData.sas_id_instrument"
            :disabled="!isEditable('sas_id_instrument')"
          />
        </div>
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_id_responsable"
            :label="$t('management.general:field.name.sas_id_responsable')"
            :validation="$v.formData.sas_id_responsable"
            :disabled="!isEditable('sas_id_responsable')"
          />
          <InputField
            v-model="formData.sas_id_attendance"
            :label="$t('management.general:field.name.sas_id_attendance')"
            :validation="$v.formData.sas_id_attendance"
            :disabled="!isEditable('sas_id_attendance')"
          />
        </div>
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_id_credentiated_employee"
            :label="$t('management.general:field.name.sas_id_credentiated_employee')"
            :validation="$v.formData.sas_id_credentiated_employee"
            :disabled="!isEditable('sas_id_credentiated_employee')"
          />
          <InputField
            v-model="formData.sas_name_instructure"
            :label="$t('management.general:field.name.sas_name_instructure')"
            :validation="$v.formData.sas_name_instructure"
            :disabled="!isEditable('sas_name_instructure')"
          />
        </div>
        <InputField
          v-model="formData.sas_address"
          :label="$t('management.general:field.name.sas_address')"
          :validation="$v.formData.sas_address"
          :disabled="!isEditable('sas_address')"
        />
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_complement"
            :label="$t('management.general:field.name.sas_complement')"
            :validation="$v.formData.sas_complement"
            :disabled="!isEditable('sas_complement')"
          />
          <InputField
            v-model="formData.sas_cep"
            :label="$t('management.general:field.name.sas_cep')"
            :validation="$v.formData.sas_cep"
            :disabled="!isEditable('sas_cep')"
          />
        </div>
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_city_code"
            :label="$t('management.general:field.name.sas_city_code')"
            :validation="$v.formData.sas_city_code"
            :disabled="!isEditable('sas_city_code')"
          />
          <InputField
            v-model="formData.sas_neighborhood_code"
            :label="$t('management.general:field.name.sas_neighborhood_code')"
            :validation="$v.formData.sas_neighborhood_code"
            :disabled="!isEditable('sas_neighborhood_code')"
          />
          <InputField
            v-model="formData.sas_state_code"
            :label="$t('management.general:field.name.sas_state_code')"
            :validation="$v.formData.sas_state_code"
            :disabled="!isEditable('sas_state_code')"
          />
        </div>
        <Action
          class="col-6"
          type="button"
          secondary
          large
          :text="$t('global:form.save')"
          submit
        />
      </form>

      <form
        v-if="canWriteSearchEngine && activeTab === 1"
        @submit.prevent="submitSAS"
      >
        <FormSection :title="$t('management.general:field.name.search_engine.stop_words')">
          <Tagify
            v-model="formData.search_engine_stop_words"
            :placeholder="$t('global:form.type.here')"
          />
        </FormSection>

        <FormSection :title="$t('management.general:field.name.search_engine.searchable_attributes')">
          <ul class="tags-list">
            <li
              v-for="(item, index) in searchableAttributesList"
              :key="index"
              class="tag-item"
            >
              <Action
                :text="$t(`management.general:searchengine.attribute.${item.value}`)"
                primary
                type="button"
                :class="{active: hasAttribute(item.value)}"
                @click="changeAttribute(item.value)"
              />
            </li>
          </ul>
        </FormSection>

        <FormSection
          v-show="false"
          :title="$t('management.general:field.name.search_engine.synonyms')"
        >
          <ul class="question-answers-items">
            <li
              v-for="(answer, index) in synonyms"
              :key="index"
              class="question-answers-item flex-row"
            >
              <InputField
                v-model="answer.item"
                :label="$t('management.general:field.name.search_engine.synonyms')"
              />
              <Action
                :text="$t('global:form.management.survey.questions.answer.remove')"
                icon="delete"
                icon-right
                flat
                type="button"
                @click="removeSynonym(index)"
              />
            </li>
          </ul>
          <Action
            :text="$t('management.general:search_engine.add.synonyms')"
            primary
            type="button"
            @click="addSynonyms()"
          />
        </FormSection>

        <FormSection class="form-section-center">
          <Action
            class="col-6"
            type="button"
            secondary
            large
            submit
            :text="$t('global:form.save')"
          />
        </FormSection>
      </form>

      <form
        v-if="activeTab === 2"
        class="management-settings_form"
        @submit.prevent="submitFinancialConfiguration"
      >
        <FormSection :title="$t('management.general:field.name.config.payment')">
          <SelectField
            v-model="formData.financial_provider"
            :label="$t('management.general:field.name.financial_provider')"
            :items="providerList"
            :validation="$v.formData.financial_provider"
            :disabled="!isEditable('financial_provider')"
          />

          <SelectField
            v-if="formData.financial_provider"
            v-model="formData.paymentType"
            :label="$t('management.general:field.name.paymentType')"
            :items="paymentTypeList"
            multiple
            :validation="$v.formData.paymentType"
            :disabled="!isEditable('paymentType')"
          />
        </FormSection>

        <FormSection
          v-if="formData.financial_provider && isCreditCardSelected"
          :title="$t('management.general:field.name.financial_credit_card_allows_installment')"
        >
          <Radio
            v-model="formData.financial_credit_card_allows_installment"
            :items="yesNoOptions"
            horizontal
            :validation="$v.formData.financial_credit_card_allows_installment"
          />
        </FormSection>

        <div
          v-if="formData.financial_provider && isCreditCardSelected
            && formData.financial_credit_card_allows_installment"
          class="row no-margin"
        >
          <FormSection class="form-section-center col-4">
            <div class="form-item has-value has-floating-label">
              <label
                class="form-label"
              >{{ $t('management.general:field.name.financial_credit_card_minimum_installment_value') }}
              </label>
              <div class="form-input-wrapper">
                <input
                  v-model="formData.financial_credit_card_minimum_installment_value"
                  v-money="money"
                  class="form-input"
                  type="text"
                >
              </div>
              <div
                v-if="minimumInstallmentValueHasError"
                class="form-input-details"
              >
                <div class="form-input-messages-container">
                  <span class="icon-wrapper is-small">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-error"
                    >
                      <use xlink:href="#icon-error" />
                    </svg>
                  </span>
                  <span class="form-input-message">{{ $t('global:validation.required') }}</span>
                </div>
              </div>
            </div>
          </FormSection>

          <FormSection class="form-section-center col-4">
            <InputField
              v-model="formData.financial_credit_card_maximum_amount_installments"
              type="number"
              :min="2"
              :max="12"
              :label="$t('management.general:field.name.financial_credit_card_maximum_amount_installments')"
              :validation="$v.formData.financial_credit_card_maximum_amount_installments"
            />
          </FormSection>
        </div>

        <SelectField
          v-model="formData.financial_receipt"
          :label="$t('management.general:field.name.financial_receipt')"
          :items="receiptList"
          :validation="$v.formData.financial_receipt"
          :disabled="!isEditable('financial_receipt')"
        />

        <FormSection :title="$t('management.general:field.name.rm_enable_integration')">
          <Radio
            v-model="formData.rm_enable_integration"
            :items="yesNoOptions"
            horizontal
            :validation="$v.formData.rm_enable_integration"
          />
        </FormSection>

        <FormSection v-if="formData.rm_enable_integration">
          <SelectField
            v-model="formData.rm_provider"
            :label="$t('management.general:field.name.rm_provider')"
            :items="rmList"
            :validation="$v.formData.rm_provider"
            :disabled="!isEditable('rm_provider')"
          />
        </FormSection>

        <FormSection
          v-if="formData.rm_enable_integration"
          :title="$t('management.general:field.name.rm_credentials')"
        >
          <InputField
            v-model="formData.rm_url"
            type="text"
            :label="$t('management.general:field.name.rm_credentials_url')"
            :validation="$v.formData.rm_url"
          />

          <InputField
            v-model="formData.rm_user"
            type="text"
            :label="$t('management.general:field.name.rm_credentials_user')"
            :validation="$v.formData.rm_user"
          />

          <InputField
            v-model="formData.rm_password"
            type="text"
            :label="$t('management.general:field.name.rm_credentials_password')"
            :validation="$v.formData.rm_password"
          />

          <InputField
            v-model="formData.rm_password_base64"
            type="text"
            :label="$t('management.general:field.name.rm_credentials_password_base64')"
            :validation="$v.formData.rm_password_base64"
          />
        </FormSection>

        <FormSection
          v-if="formData.rm_enable_integration"
          :title="$t('management.general:field.name.rm_centro_de_custo')"
        >
          <InputField
            v-model="formData.rm_centro_de_custo"
            mask="#####.######.###"
            :validation="$v.formData.rm_centro_de_custo"
          />
        </FormSection>

        <FormSection
          v-if="formData.rm_enable_integration"
          :title="$t('management.general:field.name.title.rm_distribute_cost_center')"
        >
          <Radio
            v-model="formData.distribute_cost_center"
            :under-description="$t('management.general:field.name.description.rm_distribute_cost_center')"
            :items="yesNoOptions"
            horizontal
            :validation="$v.formData.distribute_cost_center"
          />
        </FormSection>

        <FormSection v-if="formData.distribute_cost_center && formData.rm_enable_integration">
          <div
            class="subtitle-content row no-margin"
          >
            <p class="subtitle">
              {{ $t('management.general:field.name.subtitle.rm_regional_office') }}
            </p>
            <p class="subtitle">
              {{ $t('management.general:field.name.subtitle.rm_cost_center') }}
            </p>
          </div>
          <div
            v-for="(item, index) in paginationListER"
            :key="item.id"
            class="row no-margin list-cost-center"
          >
            <InputField
              :value="formatRegionalOffice(item.regionalOffice)"
              disabled
            />
            <InputField
              v-model="item.costCenter"
              mask="#####.######.###"
              :validation="$v.formData.rm_distribute_cost_center_list.$each[getIndexWithPage(index)].costCenter"
            />
          </div>
        </FormSection>
        <Pagination
          v-if="formData.rm_enable_integration && formData.distribute_cost_center"
          :active-page="paginationCostCenter.page"
          :page-count="paginationCostCenter.lastPage"
          scroll-to-this-elem=".list-cost-center"
          @firstPage="firstPage"
          @lastPage="lastPage"
          @nextPage="nextPage"
          @previousPage="prevPage"
        />

        <FormSection class="form-section-center">
          <Action
            class="col-6"
            type="button"
            secondary
            large
            submit
            :text="$t('global:form.save')"
            :disabled="cantSaveFinancialSettings"
          />
        </FormSection>
      </form>

      <form
        v-if="activeTab === 3"
        @submit.prevent="submitSAS"
      >
        <InputField
          v-model="formData.rae_product_code_initial"
          :label="$t('manegement.general:field.name.rae_product_code_initial')"
          type="number"
          :validation="$v.formData.rae_product_code_initial"
          :disabled="!isEditable('rae_product_code_initial')"
        />
        <InputField
          v-model="formData.rae_product_code_expired"
          :label="$t('manegement.general:field.name.rae_product_code_expired')"
          type="number"
          :validation="$v.formData.rae_product_code_expired"
          :disabled="!isEditable('rae_product_code_expired')"
        />
        <InputField
          v-model="formData.rae_product_code_dropout"
          :label="$t('manegement.general:field.name.rae_product_code_dropout')"
          type="number"
          :validation="$v.formData.rae_product_code_dropout"
          :disabled="!isEditable('rae_product_code_dropout')"
        />
        <InputField
          v-model="formData.rae_product_code_canceled"
          :label="$t('manegement.general:field.name.rae_product_code_canceled')"
          type="number"
          :validation="$v.formData.rae_product_code_canceled"
          :disabled="!isEditable('rae_product_code_canceled')"
        />

        <FormSection class="form-section-center">
          <Action
            class="col-6"
            type="button"
            secondary
            large
            submit
            :text="$t('global:form.save')"
          />
        </FormSection>
      </form>

      <form
        v-if="activeTab === 4"
        @submit.prevent="submitSAS"
      >
        <InputField
          v-model="formData.moodle_token"
          :label="$t('manegement.general:field.name.moodle_token')"
          type="text"
          :validation="$v.formData.moodle_token"
        />
        <InputField
          v-model="formData.moodle_url"
          :label="$t('manegement.general:field.name.moodle_url')"
          type="text"
          :validation="$v.formData.moodle_url"
        />

        <FormSection class="form-section-center">
          <Action
            class="col-6"
            type="button"
            secondary
            large
            submit
            :text="$t('global:form.save')"
          />
        </FormSection>
      </form>

      <form
        v-if="activeTab === 5"
        @submit.prevent="submitSAS"
      >
        <InputField
          v-model="formData.sparkpost_api_url"
          :label="$t('management.general:field.name.sparkpost_api_url')"
          :validation="$v.formData.sparkpost_api_url"
          :disabled="!isEditable('sparkpost_api_url')"
        />
        <InputField
          v-model="formData.sparkpost_key"
          :label="$t('management.general:field.name.sparkpost_key')"
          :validation="$v.formData.sparkpost_key"
          :disabled="!isEditable('sparkpost_key')"
        />
        <Action
          class="col-6"
          type="button"
          secondary
          large
          :text="$t('global:form.save')"
          submit
        />
      </form>

      <p v-show="!canWriteSearchEngine && activeTab === 1">
        Você não tem permissão para configurar a busca
      </p>
    </div>

    <ModalConfirm
      :active="modalRm"
      :title="$t('management.general:syncall.title')"
      :description="$t('management.general:syncall.description')"
      @confirmAction="syncRm"
      @cancelAction="cancelRemove"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>

<style lang="scss" scoped>
.management-settings_form {
  .row {
    justify-content: space-between;

    .form-item {
      flex: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    &.subtitle-content {
      .subtitle {
        flex: 1;
        font-family: 'Lato';
        font-size: 1.8em;
        font-weight: bold;
        letter-spacing: 0.9px;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: 16px;

        &:first-child {
          margin-right: 24px;
        }
      }
    }
  }

  .pagination {
    margin-bottom: 64px;
  }
}

.management-general {
  .row {
    justify-content: space-between;

    .form-section {
      margin: 0 15px 0 0;
      flex: 1;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.form-section {
  margin-top: 0;
}
</style>
